import { TYPE } from "vue-toastification";
import { apiCall, logResponse } from "@/core/api";
import { showAlert } from "@/core/popup";

export async function getConfigurationsList(): Promise<any> {
  const resource = "/configurations";
  const response = await apiCall("GET", resource);
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return undefined;
}

export async function getConfiguration(configurationId: string): Promise<any> {
  const endPoint = `/configurations/${configurationId}`;
  const response = await apiCall("GET", endPoint);
  if (response.code === 200) {
    return response.result[0];
  }
  logResponse(response);
  return undefined;
}

export async function deleteConfiguration(configurationId: string): Promise<any> {
  const endPoint = `/configurations/${configurationId}`;
  const response = await apiCall("DELETE", endPoint);
  if (response.code === 200) {
    return true;
  }
  logResponse(response);
  return false;
}

export async function addConfiguration(configuration: any): Promise<any> {
  const endPoint = "/configurations/create";
  const response = await apiCall("POST", endPoint, configuration);

  if (response.code === 200) {
    return response.result;
  }

  if (response.code === 406) {
    showAlert(TYPE.WARNING, response.message);
    return undefined;
  }
  logResponse(response);
  return undefined;
}

export async function editConfiguration(configuration: any): Promise<any> {
  const endPoint = "/configurations/update";
  const response = await apiCall("PUT", endPoint, configuration);
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return undefined;
}

export async function getConfigurationNames(): Promise<any> {
  const endPoint = "/configurations/names";
  const response = await apiCall("GET", endPoint);
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return undefined;
}

export async function getConfigurationTypes(): Promise<any> {
  const endPoint = "/configurations/types";
  const response = await apiCall("GET", endPoint);
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return undefined;
}
